.nav {
  &--logo {
    display: flex;
    width: 109px;
    height: 60px;
    margin: 12px auto 42px;

    > img {
      display: block;
      width: auto;
      height: 60px;
    }
  }
}
