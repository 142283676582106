.label-number-item {
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  gap: 2px;
  padding: 4px 8px;
  background-color: #f4f4f4;
  margin-bottom: 4px;
  border-radius: 8px;
  box-sizing: border-box;

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
  }
}
