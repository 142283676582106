.header {
  position: fixed;
  right: 0;
  display: flex;
  height: 64px;
  z-index: 99;

  &--wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 24px;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(6px);
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }
}
