.snackbar {
  &.snackbar-success {
    .MuiSnackbarContent-root {
      background-color: #4caf50;
    }
  }

  .MuiSnackbarContent-message {
    width: 100%;
  }
}
